<template>
	<div class="container">
		<back />
		<div class="header">
			<div style="margin-right: auto; margin-left: 150px">
				<el-button-group>
					<el-button type="primary" @click="changePage('/step/two')" icon="el-icon-arrow-left">上一步
					</el-button>
					<el-button type="primary" @click="changePage('/step/four')">下一步<i
							class="el-icon-arrow-right el-icon--right"></i></el-button>
				</el-button-group>
			</div>
			<el-select v-model="value" placeholder="请选择" @change="changeMon">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
		</div>

		<div class="stepOne">
			<div class="box-left">
				<h3>
					第三步：经营差异分析
				</h3>
				<div class="img">
					<img src="https://oss.culturalcloud.net/furong/202211/06205902zuq9.png" alt="" />
				</div>
				<span
					style="font-size: 22px; text-align: center">根据一线人员的市场接触情况，准确的反馈“客户需要什么”，在此基础上，分析满足客户差异需要的条件，根据公司现实和未来的内外状况，研究是否具有相应的实力，目的是明确“本企业能为顾客提供什么”这一主题。</span>
				<userMenu :userId="$route.params.useId" :nickName="$route.params.nickName"></userMenu>
			</div>
			<div class="box-right">
				<div class="panel-box">
					<div
						style="width: 100%;height: 100px;font-size: 26px;font-weight: bold;text-align: center;line-height: 100px;">
						经营差异分析</div>
					<div
						style="width: 100%;height: calc(100% - 100px);display: flex;justify-content: space-between;padding: 0 50px 50px;">
						<div class="panel-left">
							<div class="title">
								客户需要什么
							</div>
							<div class="content">
								<table-edit @change="edit" type="textarea" v-model="form.need">
								</table-edit>
							</div>
						</div>
						<div class="panel-right">
							<div class="title">
								能为客户提供什么
							</div>
							<div class="content">
								<table-edit @change="edit" type="textarea" v-model="form.provide">
								</table-edit>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAnalysis,
		saveAnalysis
	} from "@/api";
	import back from "@/components/back";
	import tableEdit from "@/components/table-edit";
	export default {
		name: "work",
		components: {
			back,
			tableEdit
		},
		data() {
			return {
				title: "",
				form: {},
				options: [],
				value:new Date().getFullYear()+'-'+(new Date().getMonth()+1),
			};
		},
		methods: { 
			edit() {
				saveAnalysis(this.form).then((res) => {
					this.$message({
						message: "内容修改成功!",
						type: "success",
					});
					this.getAnalysisData();
				});
			},
			//切换页面
			changePage(path) {
				this.$router.push(path + '/' + this.$route.params.userId + '/' + this.$route.params.nickName)
			},
			getAnalysisData() {

				getAnalysis({
					time: this.value,
					userId: this.$route.params.userId
				}).then((res) => {
					if (res.code === 200) {
						this.form = res.data
						this.form.userId = this.$route.params.userId
						this.options = res.month.map((item) => {
							return {
								value: item,
								label: item
							}
						})
					}
				});
			},
			changeMon(e) {
				this.getAnalysisData();
			},
		},
		created() {
			// console.log(this.$route.params.useId);
			this.getAnalysisData();
		},
	};
</script>
<style>
	.iconfont {
		font-size: 24px;
	}
</style>
<style lang="scss" scoped>
	.container {
		background-color: #fffcfa;
		height: 100vh;
		padding: 30px;

		.header {
			// width: 100%;
			margin-top: 50px;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.step {
				width: 100%;

				display: flex;
				justify-content: space-around;
				text-align: center;
				vertical-align: middle;
				align-items: center;

				color: #fff;

				span {
					border-radius: 5px;
					line-height: 40px;
					background-color: #b99f89;
					display: block;
					width: 17%;
				}
			}
		}

		.stepOne {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 900px;

			.box-left {
				// border: 1px solid #000;
				width: 30%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				h3 {
					font-size: 30px;
					width: 100%;
					text-align: center;
					line-height: 80px;
					background-color: #faf5f0;
					margin-bottom: 30px;
					font-weight: bold;
					color: #a46e39;
					border: 3px #a46e39 dashed;
					border-radius: 10px;
				}

				.img {
					width: 100%;

					img {
						width: 100%;
					}
				}
			}

			.box-right {
				width: 65%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.panel-box {
					width: 90%;
					height: 600px;
					background: #FFFFFF;
					box-shadow: 3px 4px 22px 0px rgba(0, 0, 0, 0.1);
					border-radius: 10px;
					display: flex;
					flex-direction: column;

					h2 {
						text-align: center;
						padding: 10px 0;
					}

					.panel-left,
					.panel-right {
						width: 48%;
						height: 100%;
						border: 2px solid rgba(0, 0, 0, .2);
						border-radius: 15px;
						display: flex;
						flex-direction: column;

						.title {
							font-size: 18px;
							font-weight: bold;
							text-align: center;
							width: 100%;
							height: 40px;
							line-height: 40px;
						}

						.content {
							width: 100%;
							height: calc(100% - 40px);

							div {
								width: 100%;
								height: 100%;
								padding: 10px 20px;
								overflow: auto;
								white-space: pre-wrap;
								font-size: 18px;
								line-height: 1.5;
								color: #606266;
								font-family: monospace;
							}
						}
					}
				}
			}
		}
	}
</style>
